/* @flow */

import * as React from 'react';

import {Button, type ButtonVariant} from 'shells/button';
import {LoadingIcon} from 'shells/icon';
import {colors} from 'shells/colors';

import type {LoginVariant} from '../login-page';

import '../login-form.css';

type Props = {|
    bttnVariant: ButtonVariant,
    submitting?: boolean,
    onClick?: () => void,
    children?: React.Node,
    pageVariant?: LoginVariant,
|};

export function LoginFlowButton(props: Props) {
    const loginCopy = props.submitting ? 'Logging in...' : 'Log in';
    const continueCopy = props.submitting ? 'Finding user...' : 'Continue';

    return (
        <div styleName='button submit'>
            <Button
                variant={props.bttnVariant}
                type='submit'
                size='xlarge'
                disabled={props.submitting}
                isFullWidth={true}
                onClick={props.onClick}
            >
                <div className='flex full-width full-height align-center justify-center gap-8'>
                    {props.children ? (
                        props.children
                    ) : (
                        <>
                            {props.pageVariant === 'sign-in' ? continueCopy : loginCopy}
                            {props.submitting && (
                                <div>
                                    <LoadingIcon fill={colors.white} size={29} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Button>
        </div>
    );
}
