/* @flow */

import * as React from 'react';

import type {EventForTimeline} from '../types';

import {TimelineBanner} from './timeline-banner';
import {getQuoteLinkText, getTargetEntities, makeTargetEntitiesDisplay} from './helpers';

import './timeline-banner.css';

type Props = {event: EventForTimeline};

const USER_DRIVEN_EVENTS = ['published_time', 'sent_time', 'revoked_time'];
const ALL_EVENTS = ['published_time', 'sent_time', 'revoked_time', 'accepted_time'];

export function TimelineBannerQuote(props: Props) {
    const {event} = props;
    const {payload, changes} = event;

    const getAction = (
        attribute: string,
        quoteNumber: string,
        value: string,
        targetEntities: React.Element<any>,
        quoteModalUrl: string
    ) => {
        const quoteNumberBold = getQuoteLinkText(quoteModalUrl, quoteNumber);

        switch (attribute) {
            case 'published_time':
                return (
                    <span>
                        marked quote {quoteNumberBold} as ready for {targetEntities}
                    </span>
                );
            case 'sent_time':
                return (
                    <span>
                        sent quote {quoteNumberBold} to {targetEntities}
                    </span>
                );
            case 'revoked_time':
                return (
                    <span>
                        revoked quote {quoteNumberBold} for {targetEntities}
                    </span>
                );
            case 'accepted_time':
                return (
                    <span>
                        {targetEntities} accepted quote {quoteNumberBold} valued at&nbsp;
                        {value}
                    </span>
                );
            case 'archived_time':
                return (
                    <span>
                        archived quote {quoteNumberBold} for {targetEntities}
                    </span>
                );
            default:
                return <></>;
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Quote' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Quote') {
        const change = changes.find((c) => ALL_EVENTS.includes(c.attribute));

        const targetEntities = getTargetEntities(payload.lead);

        const targetEntitiesDisplay = makeTargetEntitiesDisplay(
            targetEntities,
            payload.lead.name,
            payload.lead.htmlUrl
        );

        const quoteModalUrl = payload.modalUrl;
        const displayNumber = payload.displayNumber;
        const attribute = change ? change.attribute : '';
        const totalValue = payload.total.formattedWithCents;

        // If this is a recipient driven event, we want to inject our own actor
        // so we can display both company and contacts
        const actor = USER_DRIVEN_EVENTS.includes(attribute) ? payload.creatorUser : null;
        const omitActor = actor === null;

        const action = getAction(
            attribute,
            displayNumber,
            totalValue,
            targetEntitiesDisplay,
            quoteModalUrl
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='quote'
                timestamp={event.changeTime}
                actor={actor}
                action={action}
                omitActor={omitActor}
            />
        );
    }

    return <></>;
}
