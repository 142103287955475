/* @flow */

import * as React from 'react';

import type {EventForTimeline} from '../types';

import {TimelineBanner} from './timeline-banner';
import {getQuoteLinkText, getTargetEntities, makeTargetEntitiesDisplay} from './helpers';

import './timeline-banner.css';

type Props = {event: EventForTimeline};

export function TimelineBannerQuoteView(props: Props) {
    const {event} = props;
    const {payload} = event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'QuoteView' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'QuoteView') {
        const targetEntities = getTargetEntities(payload.quote.lead);

        const targetEntitiesDisplay = makeTargetEntitiesDisplay(
            targetEntities,
            payload.quote.lead.name,
            payload.quote.lead.htmlUrl
        );

        const quoteModalUrl = payload.quote.modalUrl;
        const quoteDisplayNumber = payload.quote.displayNumber;
        const quoteNumberBold = getQuoteLinkText(quoteModalUrl, quoteDisplayNumber);

        const action = (
            <span>
                {targetEntitiesDisplay} viewed quote {quoteNumberBold}
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='quote'
                timestamp={event.changeTime}
                actor={null}
                action={action}
                omitActor={true}
            />
        );
    }

    return <></>;
}
