/* @flow */

import * as React from 'react';
import {colors} from '../colors';
import type {ButtonVariant} from '../button/types';

import './button-icon.css';

export const sizes = {
    mini: 'mini',
    tiny: 'tiny',
    small: 'small',
    normal: 'normal',
    big: 'big',
    xlarge: 'xlarge',
};

type Props = {
    variant?: ButtonVariant,
    size?: $Keys<typeof sizes>,
    icon: React.ComponentType<*>,
};

export function ButtonIcon(props: Props) {
    const Icon = props.icon;
    const {size = 'normal', variant = 'secondary'} = props;

    return (
        <div styleName='button-icon'>
            <Icon wrapWithDiv={false} size={getIconSize(size)} fill={getFillColor(variant)} />
        </div>
    );
}

function getFillColor(variant: ButtonVariant) {
    switch (variant) {
        case 'primary':
        case 'orange':
        case 'save':
        case 'danger':
        case 'ai-blue':
            return '#fff';
        case 'secondary':
            return colors.grey;
        case 'text-primary':
            return colors.blue;
        default:
            return colors.grey;
    }
}

function getIconSize(size: $Keys<typeof sizes>) {
    switch (size) {
        case 'mini':
            return 11;
        case 'tiny':
            return 12;
        case 'small':
        case 'normal':
            return 13;
        case 'big':
            return 15;
        case 'xlarge':
            return 17;
    }
}
