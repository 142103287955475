/* @flow */

import * as React from 'react';

import type {GetQuotes_quotes as Quote, QuoteDisplayStatus} from 'nutshell-graphql-types';
import type {QuoteTimes} from '../../../../../../ui/lead/quotes/helpers';

import {Stack} from '../../layout';
import {
    DownloadIcon,
    NavIconWebsiteIcon,
    HtmlLinkIcon,
    CopyIcon,
    SendIcon,
    AcceptedIcon,
    MinusCircleIcon,
    ArchiveIcon,
    EmailIcon,
} from '../../icon';
import {ActionMenu} from '../../action-menu';
import {TwoLine} from '../../two-line';
import {StatusLabel} from '../../quotes/status/status-label';
import {Link} from '../../link';

import {getDisplayStatus} from './helpers';

import './quote-row.css';

type Props = {|
    quote: Quote,
    handleDownload: (slug: string) => void,
    handleUpdateStatus: (id: string, status: QuoteDisplayStatus) => void,
    handleDuplicateQuote: (id: string) => void,
    handleSendViaEmail: () => void,
    quoteTimes: QuoteTimes,
|};

export function QuoteRow(props: Props) {
    const scriptRef = React.createRef<HTMLElement>();

    const options = [
        {
            label: 'View as customer',
            icon: <NavIconWebsiteIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                window.open(props.quote.shareableViewInBrowserLink, '_blank');
            },
        },
        {
            label: 'Copy link',
            icon: <HtmlLinkIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                navigator.clipboard.writeText(props.quote.shareableViewInBrowserLink);
            },
        },
        {
            label: 'Download a PDF',
            icon: <DownloadIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                if (props.quote.slug) {
                    props.handleDownload(props.quote.slug);
                }
            },
        },
        {
            label: 'Make a copy',
            icon: <CopyIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                props.handleDuplicateQuote(props.quote.id);
            },
        },
    ];

    if (props.quote.displayStatus === 'PUBLISHED') {
        if (NutClientConfig.hasQuotesFollowup) {
            options.push({
                label: 'Send via email',
                icon: <EmailIcon size={15} wrapWithDiv={false} />,
                handleSelect: () => {
                    props.handleSendViaEmail();
                },
            });
        }
        options.push({
            label: 'Revoke',
            icon: <MinusCircleIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                props.handleUpdateStatus(props.quote.id, 'REVOKED');
            },
        });
    } else if (props.quote.displayStatus === 'DRAFT' || props.quote.displayStatus === 'REVOKED') {
        options.push({
            label: 'Remove',
            icon: <ArchiveIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                props.handleUpdateStatus(props.quote.id, 'ARCHIVED');
            },
        });
    } else if (props.quote.displayStatus === 'SENT') {
        if (NutClientConfig.hasQuotesFollowup) {
            options.push({
                label: 'Mark as accepted',
                icon: <AcceptedIcon size={15} wrapWithDiv={false} />,
                handleSelect: () => {
                    props.handleUpdateStatus(props.quote.id, 'ACCEPTED');
                },
            });
        }
    }

    return (
        <>
            <Link
                id='edit-quote'
                href={props.quote.modalUrl}
                variant='secondary'
                size='small'
                preventUnderline={true}
                title='Open quote'
            >
                <div styleName='quote-row'>
                    <div styleName='dog-ear' />
                    <div styleName='quote-row-content'>
                        <TwoLine
                            topRow={`Quote ${props.quote.displayNumber}`}
                            bottomRow={`${props.quote.total.formattedWithCents}`}
                            isBoldTopRow={true}
                        />
                        {!NutClientConfig.hasQuotesFollowup && (
                            <div className='flex align-start gap-4 flex-dir-col'>
                                {props.quote.sentTime && (
                                    <div className='flex align-center justify-center gap-4'>
                                        <SendIcon size={10} wrapWithDiv={false} />
                                        Sent
                                    </div>
                                )}
                            </div>
                        )}
                        <StatusLabel
                            variant={
                                NutClientConfig.hasQuotesFollowup
                                    ? props.quote.displayStatus
                                    : getDisplayStatus(props.quote.displayStatus)
                            }
                            quoteStatus={props.quote.displayStatus}
                            isDisabled={true}
                            onAdvance={() => {}}
                            width={110}
                            height={30}
                            quoteTimes={props.quoteTimes}
                        />
                    </div>
                    <Stack isHorizontal={true} spacing={0} alignCenter={true}>
                        <ActionMenu
                            size='small'
                            hasHoverBackground={true}
                            padding='less'
                            options={options}
                        />
                    </Stack>
                </div>
                <div ref={scriptRef} id='nutshell-quotes-container' />
            </Link>
        </>
    );
}
