/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {type LocationShape} from 'react-router-dom';

import {ButtonIcon} from '../icon';
import type {ButtonVariant} from '../button';

import type {LinkVariant} from './types';

import './link.css';

export type Size = 'mini' | 'tiny' | 'small' | 'normal' | 'big' | 'xlarge';

type Props = {
    as?: string | React.ComponentType<*>,
    newTab?: boolean,
    children: string | React.Node,
    variant?: LinkVariant,
    size?: Size,
    icon?: React.ComponentType<*>,
    href?: ?string,
    to?: string | LocationShape,
    onClick?: (event: any) => any,
    preventUnderline?: boolean,
    isFullWidth?: boolean,
    isBold?: boolean,
    shouldAddHttpProtocol?: boolean,
    shouldTruncate?: boolean,
};

/**
 * Use this to create an anchor tag, or a react-router link by providing an `as` prop.
 * Variants can be used to control the style of the link, including making
 * it appear as a button if desired.
 */
export function Link(props: Props) {
    const {
        as = 'a',
        icon,
        variant = 'secondary',
        size = 'normal',
        children,
        newTab,
        preventUnderline,
        isFullWidth,
        isBold,
        shouldAddHttpProtocol,
        href,
        shouldTruncate,
        ...restProps
    } = props;
    const styleName = classnames(
        variant,
        size,
        {'prevent-underline': preventUnderline},
        {'full-width': isFullWidth},
        {'bold': isBold},
        {'truncate': shouldTruncate},
    );

    const Tag = as;
    const hrefPattern = /^https?:/;
    const fullHref = href && shouldAddHttpProtocol && !hrefPattern.test(href) ? `https://${href}` : href;

    return (
        <Tag
            styleName={styleName}
            target={newTab ? '_blank' : undefined}
            rel={newTab ? 'noreferrer noopener' : undefined}
            href={fullHref}
            {...restProps}
        >
            {icon ? (
                <ButtonIcon size={size} variant={getButtonVariant(variant)} icon={icon} />
            ) : (
                undefined
            )}
            {children}
        </Tag>
    );
}

function getButtonVariant(variant: LinkVariant): ButtonVariant {
    switch (variant) {
        case 'button':
        case 'button-primary':
            return 'primary';
        case 'button-ai-blue':
            return 'ai-blue';
        case 'button-save':
            return 'save';
        case 'primary':
            return 'text-primary';
        case 'button-upgrade':
            return 'upgrade';
        default:
            return 'secondary';
    }
}
