/* @flow */

import type {
    GetQuoteById_quote as QuoteType,
    GetQuotes_quotes as SideBarQuoteType,
} from 'nutshell-graphql-types';
import {analyticsClient} from '../../../analytics-client';

/**
 * Function to download a quote by fetching serialized quote config
 *
 * @param {string} instanceId
 * @param {string} slug
 * @param {HTMLElement} anchor
 * @param {string} target
 */
export const downloadQuote = (
    instanceId: string,
    slug: string,
    anchor: HTMLElement,
    target: string
) => {
    const requestPath = `/quotes-public/config/${instanceId}/${slug}`;

    fetch(requestPath)
        .then((response) => response.json())
        .then((quoteConfig) => {
            const script = document.createElement('script');
            script.src = '/peanut.js';
            script.setAttribute('action', 'quotes-download');
            script.setAttribute('instance-id', instanceId);
            script.setAttribute('target', target);
            script.setAttribute('quote-slug', slug);
            script.setAttribute('quote-config', JSON.stringify(quoteConfig.config));

            script.async = true;
            anchor.appendChild(script);

            analyticsClient.track(analyticsClient.EVENTS.TRACK_QUOTE_DOWNLOADED);
        });
};

export const preventRefetchLoadingState = (
    refetchLoading: React.MutableRefObject<boolean>,
    refetch: () => Promise<any>
) => {
    refetchLoading.current = true;
    refetch().then(() => {
        refetchLoading.current = false;
    });
};

/**
 * Embed a peanut-rendered quote in the given anchor element. The target and
 * anchor must be referring to the same element.
 */
export const previewQuote = (
    instanceId: string,
    slug: string,
    anchor: HTMLElement,
    target: string
) => {
    const requestPath = `/quotes-public/config/${instanceId}/${slug}`;

    fetch(requestPath)
        .then((response) => response.json())
        .then((quoteConfig) => {
            const script = document.createElement('script');
            script.src = '/peanut.js';
            script.setAttribute('instance-id', instanceId);
            script.setAttribute('target', target);
            script.setAttribute('quote-slug', slug);
            script.setAttribute('quote-config', JSON.stringify(quoteConfig.config));

            script.async = true;
            anchor.appendChild(script);
        });
};

export type QuoteTimes = {
    createdAt: number,
    publishedAt: number,
    acceptedAt: number,
    sentAt: number,
};

export const getQuoteTimes = (quote: QuoteType | SideBarQuoteType): QuoteTimes => {
    return {
        createdAt: quote.createdTime || 0,
        publishedAt: quote.publishedTime || 0,
        acceptedAt: quote.acceptedTime || 0,
        sentAt: quote.sentTime || 0,
    };
};

/**
 * Handle the quote change by opening the quote modal, refetching the quotes,
 * and preventing the refetch loading state
 *
 * @param {string} quoteModalUrl
 * @param {React.MutableRefObject<boolean>} refetchLoading
 * @param {() => Promise<any>} refetch
 */
export const handleQuoteChange = (
    quoteModalUrl: string,
    refetchLoading: React.MutableRefObject<boolean>,
    refetch: () => Promise<any>
) => {
    window.open(quoteModalUrl, '_self');
    preventRefetchLoadingState(refetchLoading, refetch);
};
