/* @flow */

import * as React from 'react';

import classnames from 'classnames';

import './body.css';

export type TextSize = 'mini' | 'tiny' | 'small' | 'normal' | 'large' | 'xlarge';

export type Color =
    | 'light'
    | 'medium'
    | 'dark'
    | 'person'
    | 'error'
    | 'blue'
    | 'white'
    | 'green'
    | 'blue-dk'
    | 'navy-dk'
    | 'warn'
    | 'placeholder';

type Props = {
    children?: React.Node,
    as?: string,
    size?: TextSize,
    color?: Color,
    isBold?: boolean,
    isItalic?: boolean,
    shouldTruncate?: boolean,
    truncateInline?: boolean,
    getBodyRef?: any,
    title?: string,
    underline?: boolean,
    strikethrough?: boolean,
    nowrap?: boolean,
    wrapAnywhere?: boolean,
};

export function Body(props: Props) {
    const {
        as = 'span',
        size = 'normal',
        color = 'medium',
        isBold,
        isItalic,
        underline,
        strikethrough,
        shouldTruncate,
        getBodyRef,
        title,
        nowrap,
        wrapAnywhere,
        truncateInline,
    } = props;

    const Tag = shouldTruncate ? 'div' : as;

    const styleNames = classnames({
        body: true,
        mini: size === 'mini',
        tiny: size === 'tiny',
        small: size === 'small',
        normal: size === 'normal',
        large: size === 'large',
        xlarge: size === 'xlarge',
        light: color === 'light',
        medium: color === 'medium',
        dark: color === 'dark',
        person: color === 'person',
        error: color === 'error',
        blue: color === 'blue',
        white: color === 'white',
        green: color === 'green',
        placeholder: color === 'placeholder',
        'blue-dk': color === 'blue-dk',
        'navy-dk': color === 'navy-dk',
        warn: color === 'warn',
        bold: isBold,
        italic: isItalic,
        underline,
        strikethrough,
        truncate: shouldTruncate,
        nowrap,
        'wrap-anywhere': wrapAnywhere,
        'truncate-inline': truncateInline,
    });

    return (
        <Tag styleName={styleNames} ref={getBodyRef} title={title}>
            {props.children}
        </Tag>
    );
}
